//
import PProviderService from '@/services/api/Provider/PProvider'
import RulesService from '@/services/rules'

//
export default {
  name: 'v-view-provider-projects',
  data() {
    return {
      propsSubmit:{
        color:'secondary',
        plain:false,
        text:false,
      },
      resetProps:{
        color:'primary',
        text:false,
      },
      fieldProps:{
        color:'#f8f4fc',
        label: "Termino", 
        outlined: true
      },
      busy: {
        create: false,
        delete: false,
        update: false,  
        provider: false,    
      },
      dialogs: {
        create: false,
        delete: false,
        update: false,
      },
      pProvider: {},
      pProviderProject: {},
      pProviderProjectUpdate: {},
      pProviderProjectReset: {},
      pProviderProjectDeleteId: null,
      pProviderProjects: {
        data: []
      },
    }
  },
  methods: {

    /**
     * 
     */
    async handleCreateReset() {
      this.pProviderProject = PProviderService.toProject()
    },

    /**
     * 
     */
    async handleCreateSubmit() {
      this.busy.create = true
      await PProviderService.project(this.pProvider, this.pProviderProject).then(this.handleSuccess).catch(this.handleError).finally(this.handleReset)
      this.busy.create = false
      this.dialogs.create = false
    },

    /**
     * 
     */
    handleDeleteClick(pProviderProjectDelete) {
      this.dialogs.delete = true
      this.pProviderProjectDeleteId = pProviderProjectDelete.id
    },

    /**
     * 
     */
    async handleDeleteSubmit() {
      this.busy.delete = true
      await PProviderService.projectDelete(this.pProvider, this.pProviderProjectDeleteId).then(this.handleSuccess).catch(this.handleError).finally(this.handleReset)
      this.busy.delete = false
    },

    /**
     * 
     */
    async handleDownloadClick(pProviderProject) {
      this.busy.provider = true
      await PProviderService.projectDownload(this.pProvider, pProviderProject)
      this.busy.provider = false      
    },

    /**
     * 
     */
    async handleReset() {
      this.busy.provider = true
      this.pProvider = await PProviderService.getById(this.$provider.id)
      this.pProviderProjects = await this.pProvider.many('project').query().param('get_all_results', 'true').get()
      this.busy.provider = false
    },

    /**
     * 
     */
    handleUpdateClick(pProviderProjectUpdate) {
      this.dialogs.update = true
      this.pProviderProjectReset  = { ...pProviderProjectUpdate }
      this.pProviderProjectUpdate = { ...pProviderProjectUpdate }
    },

    /**
     * 
     */
    handleUpdateReset() {
      this.pProviderProjectUpdate = { ...this.pProviderProjectReset }
    },

    /**
     * 
     */
    async handleUpdateSubmit() {
      this.busy.update = true
      await PProviderService.projectUpdate(this.pProvider, this.pProviderProjectUpdate).then(this.handleSuccess).catch(this.handleError)
      await this.handleReset()
      this.busy.update = false
    },

    /**
     * 
     */
    handleError(payload) {
      PProviderService.translate(payload).then(this.$toast.error)
    },

    /**
     * 
     */
    handleSuccess(payload) {
      this.dialogs.create = false
      this.dialogs.delete = false
      this.dialogs.update = false
      PProviderService.translate({ response: payload }).then(this.$toast.success)
    },
  },
  created() {
    this.handleReset()
    this.handleCreateReset()
  },
  props: {

    /**
     * 
     */
    fileRules: {
      type: Array,
      default: () => [
        RulesService.required,
      ],
    },

    /**
     * 
     */
    nameRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.max(200),
      ],
    },
  }
}